import React, { useState } from 'react';
import { Nav, Navbar, Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MonthlyLogo from "../../assets/img/brand/logo-dark-bg.png";
import { AppRoutes } from "../../routes";
import { useNavigate, Link } from "react-router-dom";
import { faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


export default () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  // Scroll spy for the calculation section
  const handleCalculationScroll = async (e) => {
    e.preventDefault();
    setExpanded(false);
    navigate(`${AppRoutes.Home.path}`, { state: { section: "calculator" } });
  };

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <Navbar collapseOnSelect expanded={expanded} expand="lg" variant="dark" className="new-navigation">
      <Container fluid>
        <Link to={AppRoutes.Home.path} className="navbar-brand">
          <div className="d-inline-block align-top">
            <Image alt="monthly.ng logo" src={MonthlyLogo} width={130} height={31} className="navigation-logo"/>
          </div>
        </Link>
        <>
        <Link onClick={closeNavbar} to={`${AppRoutes.Signin.path}`} className="nav-link signin smallin">SIGN IN</Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}>
          {expanded ? (
            <FontAwesomeIcon icon={faTimesCircle} className="fixed-toggler"/>
          ) : <FontAwesomeIcon icon={faBars}/>}
        </Navbar.Toggle>
        </>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end collapse-container">
          <Nav>
            <Link onClick={closeNavbar} to={`${AppRoutes.HowItWorks.path}`} className="nav-link">How It Works</Link>
            <Link onClick={closeNavbar} to={`${AppRoutes.About.path}`} className="nav-link">About Us</Link>
            <Nav.Link eventKey="3" onClick={handleCalculationScroll}>Calculator</Nav.Link>
            <Link onClick={closeNavbar} to={`${AppRoutes.Faqs.path}`} className="nav-link">FAQs</Link>

            {/* <a target ="_blank" className="nav-link" href="https://blog.monthly.ng">Blog</a> */}
            
          </Nav>
          
        </Navbar.Collapse>
        <Link onClick={closeNavbar} to={`${AppRoutes.Signin.path}`} className="nav-link signin largein ml-2">SIGN IN</Link>
      </Container>
    </Navbar>
  );
};
