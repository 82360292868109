import React from "react";
import m21 from "../../../assets/img/m21.png";
import m22 from "../../../assets/img/m22.png";
import m23 from "../../../assets/img/m23.png";
import m24 from "../../../assets/img/m24.png";
import m25 from "../../../assets/img/m25.png";
import PlainHero5 from "../../../assets/img/hero/plain-hero-5.png";
import PlainHero4 from "../../../assets/img/hero/plain-hero-4.png";
import PlainHero1 from "../../../assets/img/hero/plain-hero-1.png";
import PlainHero2 from "../../../assets/img/hero/plain-hero-2.png";
import PlainHero3 from "../../../assets/img/hero/plain-hero-3.png";
import qrcode from "../../../assets/img/qr-code.png";
// import googleplay from "../../../assets/img/newgoogleplay.png";
// import appstore from "../../../assets/img/newappstore.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const benAnime = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".benefits-container");
      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: slider.current,
          pin: true,
          scrub: 1,
          snap: 1 / (panels.length - 1),
          end: () => "+=" + slider.current.offsetWidth
        }
      });
    }, benAnime);
    return () => ctx.revert();
  });
  return (
    <div ref={benAnime}>
      <div className="benefits-animation" ref={slider}>
        <div className="benefit-content">
          <div className="benefits-container">
            <img src={PlainHero5} alt="" className="benefit-bg-img" />
            <div className="page-container">
              <div className="benefit-card">
                <div className="left-benefits-card benefit-slider-one">
                  <h2>01</h2>
                  <h6>Do more with your money</h6>
                  <p>
                Invest it or save for emergencies, the choice is yours. You
                don’t have to empty your bank account to make rent.
                  </p>
                  <h5>SCAN TO DOWNLOAD</h5>
                  <img src={qrcode} className="barcode-img" />
                  {/* <div className="home-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
              </div> */}
                </div>
                <div className="right-benefits-card">
                  <img src={m21} alt="monthly" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="benefit-content">
          <div className="benefits-container">
            <img src={PlainHero4} alt="" className="benefit-bg-img" />
            <div className="page-container">
              <div className="benefit-card benefit-slider-two">
                <div className="left-benefits-card">
                  <h2>02</h2>
                  <h6>Life doesn't have to happen all at once.</h6>
                  <p>
              Take it one month at a time. Pay your rent with the same frequency as you get paid.
                  </p>
                  <h5>SCAN TO DOWNLOAD</h5>
                  {/* <img src={qrcode} className="barcode-img" />
              <div className="home-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
              </div> */}
                </div>
                <div className="right-benefits-card">
                  <img src={m22} alt="monthly" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="benefit-content">
          <div className="benefits-container">
            <img src={PlainHero1} alt="" className="benefit-bg-img" />
            <div className="page-container">
              <div className="benefit-card benefit-slider-three">
                <div className="left-benefits-card">
                  <h2>03</h2>
                  <h6>No harassment</h6>
                  <p>
              We understand that life happens. We will never call to harass your family, friends, or co-workers.
                  </p>
                  <h5>SCAN TO DOWNLOAD</h5>
                  <img src={qrcode} className="barcode-img" />
                  {/* <div className="home-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
              </div> */}
                </div>
                <div className="right-benefits-card">
                  <img src={m23} alt="monthly" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="benefit-content">
          <div className="benefits-container">
            <img src={PlainHero2} alt="" className="benefit-bg-img" />
            <div className="page-container">
              <div className="benefit-card benefit-slider-four">
                <div className="left-benefits-card">
                  <h2>04</h2>
                  <h6>All transparent</h6>
                  <p>
              No hidden fees or charges. We’re upfront about all the numbers, all day, every day.
                  </p>
                  <h5>SCAN TO DOWNLOAD</h5>
                  <img src={qrcode} className="barcode-img" />
                  {/* <div className="home-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
              </div> */}
                </div>
                <div className="right-benefits-card">
                  <img src={m24} alt="monthly" />
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="benefit-content">
          <div className="benefits-container">
            <img src={PlainHero3} alt="" className="benefit-bg-img" />
            <div className="page-container">
              <div className="benefit-card benefit-slider-five">
                <div className="left-benefits-card">
                  <h2>05</h2>
                  <h6>Get help finding your perfect home.</h6>
                  <p>
              Finding a home can be a tough process. We will connect you to vetted agents to help you through the process.
                  </p>
                  <h5>SCAN TO DOWNLOAD</h5>
                  <img src={qrcode} className="barcode-img" />
                  {/* <div className="home-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
              </div> */}
                </div>
                <div className="right-benefits-card">
                  <img src={m25} alt="monthly" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
