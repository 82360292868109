import React from 'react';
import MonthlyLogo from "../../../assets/img/brand/logo.png";
import Tick from "../../../assets/img/svgs/check.svg";
import UnTick from "../../../assets/img/svgs/uncheck.svg";
import Testimonials from './Testimonials';

// This comparison component is also a wrapper for the testimonial sliders
export default () => {
  return (
    <>
      <section className="new-comparison" >
        <div className="page-container">
          <div className="comparison-header">
            <h3>Monthly vs Others</h3>
            <p>Monthly is hands down the best way to rent and here's why:</p>
          </div>
          <div className="comparison-wrapper">
          <div className="comparison-body">
            <div className="each-comparison-card">
              <div className="comparison-body-title" />
              <div className="card-transparent">
                <h6>Annual Interest Cost </h6>
                <h6>Deposit </h6>
                <h6>Save on interest by paying faster ?</h6>
                <h6>Maximum amount for employees</h6>
                <h6>Maximum amount for self-employed</h6>
                <h6>Guarantor required ?</h6>
                {/* <h6>Salary domiciliation required ?</h6> */}
                <h6>Access to verified agents to help you find a property ?</h6>
              </div>
            </div>
            <div className="each-comparison-card">
              <div className="comparison-body-title">
                <img src={MonthlyLogo} alt="Monthly"/>
              </div>
              <div className="card-blue">
                <div><span><img src={Tick}/></span><p>24-30%</p></div>
                <div><span><img src={Tick}/></span><p>No deposit required</p></div>
                <div><span><img src={Tick}/></span><p>Yes. Interest charged on monthly balance</p></div>
                <div><span><img src={Tick}/></span><p>4.5x salary</p></div>
                <div><span><img src={Tick}/></span><p>1.2x monthly revenue</p></div>
                <div><span><img src={Tick}/></span><p>No. A guarantor is not compulsory</p></div>
                {/* <div><span><img src={Tick}/></span><p>No</p></div> */}
                <div><span><img src={Tick}/></span><p>Yes. All approved customers get access</p></div>
              </div>
            </div>
            <div className="each-comparison-card">
              <div className="comparison-body-title">Others</div>
              <div className="card-white">
                <div><span><img src={UnTick}/></span><p>40-90%</p></div>
                <div><span><img src={UnTick}/></span><p>Yes. Up to 5 months</p></div>
                <div><span><img src={UnTick}/></span><p>No</p></div>
                <div><span><img src={UnTick}/></span><p>2-3x salary</p></div>
                <div><span><img src={UnTick}/></span><p>0-0.5x monthly revenue</p></div>
                <div><span><img src={UnTick}/></span><p>Yes</p></div>
                {/* <div><span><img src={UnTick}/></span><p>Yes</p></div> */}
                <div><span><img src={UnTick}/></span><p>No</p></div>
              </div>
            </div>
          </div>
          </div>
          <div className="testimonial-header">
            <h3>Testimonials</h3>
            <p>Trusted by renters like you</p>
          </div>
          <Testimonials/>
        </div>
      </section>
    </>
  );
};
