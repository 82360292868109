import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function NeedHelp() {
  return (
    <div>
      {" "}
      <p className="signup-review text-center">Need help?</p>
      <div className="text-center">
        <div className="signin-support">
          {" "}
          <span>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />{" "}
          </span>
          <span>09088991097</span>
        </div>
        <div className="signin-support">
          <span>
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />{" "}
          </span>
          <span className="ml-2">support@monthly.ng</span>
        </div>
        <div className="signin-support">
          {" "}
          <span>
            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />{" "}
          </span>
          <span>09161734720</span>
        </div>
      </div>
    </div>
  );
}
