import React from "react";
import { redirectToLogout } from "./Redirect";

const RequireAuth = ({ children }) => {
  const auth = JSON.parse(localStorage.getItem("auth"));

  return (
    // eslint-disable-next-line no-nested-ternary
    auth?.accessToken ? children : redirectToLogout()
  );
};

export default RequireAuth;
