import React from "react";
import ReviewAvatar from "../../../assets/img/review-avatar.png";
import ReviewAvatar2 from "../../../assets/img/review-avatar2.png";
import ReviewAvatar3 from "../../../assets/img/review-avatar3.png";
// import { Carousel } from 'react-responsive-carousel';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const EachTestimonial = ({ testimonialbody, avatar, name, extendStyle }) => (
  <div className={`testimonial-card ${extendStyle}`}>
    <p>{testimonialbody}</p>
    <div className="home-review-avatar">
      <img src={avatar} alt="review-avatar" />
      <h4>{name}</h4>
    </div>
  </div>
);

export default function Testimonials() {
  const responsiveClass = {
    0: {
      items: 1,
      margin: 10,
    },
    400: {
      items: 1,
      margin: 10
    },
    850: {
      items: 1,
      margin: 20,
    },
    991: {
      items: 2,
      margin: 100
    },
    1240: {
      items: 2,
      margin:50
    },
    1336: {
      items: 3,
      margin: 300
    },
  };
  return (
    <>
      <OwlCarousel loop autoplay responsive={responsiveClass}>
        <EachTestimonial
          extendStyle="testimonial-card-one"
          testimonialbody="“Thanks to the guys at Monthly I was able to easily
                pay for the rent for my new place easily without breaking
                a sweat and focus my finances on the several other bits that
                come with getting a new place. The customer support team was
                really helpful, quick to respond and always happy to help “"
          avatar={ReviewAvatar}
          name="Amos, Software Engineer"
        />
        <EachTestimonial
          extendStyle="testimonial-card-two"
          testimonialbody="“ Monthly was very helpful when our business needed to 
          expand, kudos to the team at monthly “"
          avatar={ReviewAvatar2}
          name="Corporate Ewa"
        />
        <EachTestimonial
          extendStyle="testimonial-card-three"
          testimonialbody="“ Monthly is a life-saver.
            I stumbled on them online and
            decided to try them out. I was first all amazed by their quick
            response. To cap it all they completely funded my new apartment which
            I currently live in. I am still amazed “"
          avatar={ReviewAvatar3}
          name="Anthony, Fashion Designer"
        />
      </OwlCarousel>
    </>
  );
}
