/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import logo from "../../assets/img/brand/logo.png";
import Trusted from "../../assets/img/svgs/collateral-icon.svg";
import HeroBanner from "../../assets/img/transparent-hero.png";
import ReviewAvatar from "../../assets/img/review-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";
import NeedHelp from "../components/NeedHelp";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(1);
  const [formDetails, setFormDetail] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [isLoadingForget, setIsLoadingForget] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [firstFormValidator, showFirstValidationMessage] = useValidator({});

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setFormDetail({ ...formDetails, [name]: value });
  };

  const { email, password, otp } = formDetails;

  const handleForgotPassword = async (e) => {
    console.log("clicked");
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setIsLoadingForget(true);
      axios
        .post(`${process.env.API_URL}/customers/${email}/update-password`, {
          new_password: password,
          otp: otp,
        })
        .then(function (response) {
          setIsLoadingForget(false);
          if (response.status === 200) {
            navigate("/signin");
          }
        })
        .catch(function (error) {
          setIsLoadingForget(false);
          toast.error(
            `Couldnt reset email address. ${error.response?.data?.message}`
          );
        });
    } else {
      showFirstValidationMessage(true);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (firstFormValidator.allValid()) {
      setIsLoadingReset(true);
      axios
        .post(`${process.env.API_URL}/customers/${email}/reset-password`)
        .then(function (response) {
          setIsLoadingReset(false);
          if (response.status === 200) {
            console.log(response);
            setPageState(2);
          }
        })
        .catch(function (error) {
          setIsLoadingReset(false);
          toast.error(`Couldnt reset email. ${error.response?.data?.message}`);
        });
    } else {
      showFirstValidationMessage(true);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="new-signup-wrapper">
        <img src={HeroBanner} alt="" className="signup-bg-img" />
        <div className="new-signup-container">
          <div className="marketing-navbar">
            <img src={logo} alt="monthly logo" />
          </div>
          <div className="new-signup-section">
            <div className="signup-left">
              <h2>
                Forgot <span>username or password? 😔</span>
              </h2>
              <div className="sub-signup-left">
                <div className="trusted-button">
                  <img src={Trusted} alt="Trusted icon" />
                  <h6>
                    Trusted by renters like <span>you</span>
                  </h6>
                </div>
                <>
                  <div className="signup-review">
                    “ Thanks to the guys at Monthly I was able to easily pay for
                    the rent for my new place easily without breaking a sweat
                    and focus my finances on the several other bits that come
                    with getting a new place. The customer support team was
                    really helpful, quick to respond and always happy to help “
                  </div>
                  <div className="review-avatar">
                    <img src={ReviewAvatar} alt="review-avatar" />
                    <h4>Amos, Software Engineer</h4>
                  </div>
                </>
              </div>
            </div>
            <div className="signup-right">
              <h1 className="mobile-signup-header">
                Forgot<span>username or password? 😔</span>
              </h1>
              {pageState === 1 ? (
                <form className="signup-form">
                  <div className="signup-formgroup">
                    <label>USERNAME OR EMAIL ADDRESS</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleFormChange}
                      value={email}
                    />
                    {firstFormValidator.message(
                      "email",
                      formDetails.email,
                      "required"
                    )}
                  </div>
                  <button className="signup-button" onClick={handleReset}>
                    {isLoadingReset ? "..." : "PROCEED"}
                  </button>
                </form>
              ) : pageState === 2 ? (
                <form className="signup-form">
                  <div className="signup-review text-center">
                    A reminder has been sent to this email along with a code to
                    change your password if you wish to
                  </div>
                  <div className="signin-support text-center">
                    The code has also been sent to your registered mobile
                    number.
                  </div>
                  {/* <div className="new-signup-subtitle text-center">
                  09090909090
                </div> */}
                  <div className="signup-formgroup">
                    <label>ENTER CODE</label>
                    <input
                      type="number"
                      name="otp"
                      onChange={handleFormChange}
                      value={otp}
                    />
                    {firstFormValidator.message(
                      "otp",
                      formDetails.otp,
                      "required"
                    )}
                  </div>
                  <div className="signup-formgroup">
                    <label>NEW PASSWORD</label>
                    <div>
                      <input
                        type={passwordType}
                        name="password"
                        onChange={handleFormChange}
                        value={password}
                      />
                      {passwordType === "password" ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={togglePassword}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                    {firstFormValidator.message(
                      "password",
                      formDetails.password,
                      "required"
                    )}
                  </div>
                  <button
                    className="signup-button"
                    onClick={handleForgotPassword}
                  >
                    {isLoadingForget ? "..." : "PROCEED"}
                  </button>
                </form>
              ) : null}

              {/* <form className="signup-form">
                <div className="signup-formgroup">
                  <label>USERNAME OR EMAIL ADDRESS</label>
                  <input
                    type="text"
                    name="username"
                    onChange={handleFormChange}
                    value={username}
                  />
                  {firstFormValidator.message(
                    "username",
                    formDetails.username,
                    "required"
                  )}
                </div>
                <div className="signup-formgroup">
                  <label>PASSWORD</label>
                  <div>
                    <input
                      type={passwordType}
                      name="password"
                      onChange={handleFormChange}
                      value={password}
                    />
                    {passwordType === "password" ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={togglePassword}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faEye} onClick={togglePassword} />
                    )}
                  </div>
                  {firstFormValidator.message(
                    "password",
                    formDetails.password,
                    "required"
                  )}
                </div>
                <button className="signup-button" onClick={handleSignIn}>
                  PROCEED
                </button>
              </form> */}
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
}
