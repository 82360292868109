import React, { useState } from 'react';
import HeroBanner from "../../assets/img/transparent-hero.png";
import logo from "../../assets/img/brand/logo.png";
import agentGift from "../../assets/img/agent-gift.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// import qrcode from "../assets/img/qr-code.png";
// import googleplay from "../assets/img/newgoogleplay.png";
// import appstore from "../assets/img/newappstore.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export default function Success () {
  const agentCode = localStorage.getItem('agent-referral') || "";
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const handleCopy = () => {
    setCopied(true);
    if (agentCode) {
      toast.success(`Referral Code copied`);
    } else {
      toast.error(`No referral code found`);
    }
  };

  const handleRouteHome = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="success-wrapper">
        <img src={HeroBanner} alt="" className="success-bg-img"/>
        <div className="page-container">
          <div className="marketing-navbar">
            <img src={logo} alt="monthly logo" onClick={handleRouteHome}/>
          </div>
          <div className="success-body">
            <div className="success-alert">

              <h6>Registration Successful <span>🎉</span></h6>
            </div>
            <img src={agentGift} alt="monthly-gift" className="monthly-agent-gift"/>
            <div className="agent-success-code">
              <div className="agent-success-referral-code">
                <p>your referral code</p>
                <h2>{agentCode} <span> <CopyToClipboard text={agentCode}
                  onCopy={handleCopy}><FontAwesomeIcon icon={faLink}/>
                </CopyToClipboard>
                </span></h2>
              </div>
              <div className="agent-referral-link-container">
                <div className="agent-referral-link">
                  <p>Invite clients and earn up to ₦150,000 per referral</p>
                  <CopyToClipboard text={`${process.env.BASE_URL}/register/${agentCode}`}
                    onCopy={handleCopy}>
                    <button> <FontAwesomeIcon icon={faLink}/> copy referral link for clients
                    </button>
                  </CopyToClipboard>
                </div>
                <div className="agent-referral-link">
                  <p>Invite agents and earn 10% of their referral earnings</p>
                  <CopyToClipboard text={`${process.env.BASE_URL}/agents/${agentCode}`}
                    onCopy={handleCopy}>
                    <button>
                      <FontAwesomeIcon icon={faLink}/> copy referral link for agents
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            {/* <div className="scan-title">
              Scan QR code below to <span>download agent app and track</span> your referrals
            </div>
            <img src={qrcode} alt="qr-code" className="success-qr"/> */}
            {/* <h5 className="small-success-title">Download <span>download agent app and track</span> your referrals</h5>
            <div className="agent-download-icons">

              <a href="https://play.google.com/store/apps/details?id=ng.monthly.mobile" target="_blank">
                <div>
                  <img src={googleplay} alt="googleplay"/>
                </div>
              </a>

              <a href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024" target="_blank">
                <div>
                  <img src={appstore} alt="appstore"/>
                </div>
              </a>
            </div> */}
          </div>
        </div>

      </div>
      {copied ? (
        <ToastContainer/>
      ) : null}
    </>
  );
}
