import React from 'react';
import { Image } from 'react-bootstrap';

import M16 from "../../../assets/img/m16.png";
import M17 from "../../../assets/img/m17.png";



export default () => {
  return (
    <>
      <section className="about-tiles">
        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
          <div className="about-tiles-section">
            <div className="about-tiles-image-box about-tiles-image-box-left">
              <Image alt="man browsing" src={M17} className="about-tiles-image"/>
            </div>
          </div>
          <div className="about-tiles-section">
            <div className="about-tiles-section-text-box about-tiles-section-text-box-right">
              <h3 className="text-bold my-4">The <span className="text-success">Why</span></h3>
              <div className="about-tiles-section-text-body">
                We believe people should be able to want things and pay for them with the same frequency as
                they earn. Being an adult is hard work and setting out on your own is a big leap. It can get
                overwhelming very quickly. What we offer is a helping hand.
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center flex-column-reverse flex-md-row ">
          <div className="about-tiles-section">
            <div className="about-tiles-section-text-box about-tiles-section-text-box-left">
              <h3 className="text-bold my-4">The <span className="text-success">How</span></h3>
              <div className="about-tiles-section-text-body">
                Easy-access, zero-collateral loans with a fixed monthly interest rate tailor-made for YOU and
                your pockets. Monthly allows you to take a leap on the things you want and need to be the best
                version of yourself—things like a place to call your own—and pay for it as you earn.
              </div>
            </div>
          </div>
          <div className="about-tiles-section">
            <div className="about-tiles-image-box about-tiles-image-box-right">
              <Image alt="lady reading" src={M16} className="about-tiles-image"/>
            </div>
          </div>
        </div>
         
      </section>
    </>
  );
};
