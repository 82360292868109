import React from 'react';
import TermsText from "./components/TermsText";

export default () => {
  return (
    <>
      <div className="container privacy-container d-flex justify-content-center">
        <div className="toc-content terms-content">
          <h1>Terms & Conditions of Website Use</h1>
          <div className="mb-4"><small>Version date: May 2022</small></div>
          <h5 className="mb-4">
            Please read this Agreement carefully before accessing the Monthly Website.
            As a condition to and in consideration of receiving and accessing the Monthly
            Website, the User agrees to be bound by the terms of this Agreement. Use of or
            access to the Monthly Website shall constitute acceptance of and agreement to be
            bound by this Agreement.
          </h5>
          <TermsText/>

        </div>
      </div>
    </>
  );
};
