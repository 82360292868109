import React from 'react';
import Header from './components/faqs/Header';
import Content from './components/faqs/Content';

export default () => {
  return (
    <>
      <Header/>
      <Content/>
    </>
  );
};
