import React from 'react';
import { Image } from 'react-bootstrap';
import M19 from "../../../assets/img/m19.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';



export default () => {
  return (
    <>
      <section className="how-to-tiles">
        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
          <div className="how-to-tiles-section">
            <div className="how-to-tiles-image-box how-to-tiles-image-box-left">
              <Image alt="woman browsing" src={M19} className="how-to-tiles-image"/>
            </div>
          </div>
          <div className="how-to-tiles-section">
            <div className="how-to-tiles-section-text-box how-to-tiles-section-text-box-right">
              <h3 className="text-bold my-4">Who can Apply?</h3>
              <div className="how-to-tiles-section-text-body">
                <div className="d-flex mb-4">
                  <div className="how-to-tiles-check me-3"><FontAwesomeIcon icon={faCheckCircle}/></div>
                  <div>18 or over</div>
                </div>
                <div className="d-flex mb-4">
                  <div className="how-to-tiles-check me-3"><FontAwesomeIcon icon={faCheckCircle}/></div>
                  <div>Earning at least ₦75,000 a month</div>
                  </div>
                  <div className="d-flex">
                  <div className="how-to-tiles-check me-3"><FontAwesomeIcon icon={faCheckCircle}/></div>
                  <div>Living & Renting in Lagos, Nigeria</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
