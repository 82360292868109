import React from 'react';

export default () => {
  return (
    <>
      <section className="faq-header page-padding">
        <div className="container text-center">
          <h2 className="text-bold">Frequently Asked</h2>
          <h2 className="text-bold">Questions</h2>
          <p className="mt-4">Have questions? We're here to help.</p>
        </div>
      </section>
    </>
  );
};
