import React from 'react';
import m21 from "../../../assets/img/mobile-images-lg.png";
import HeroBanner from "../../../assets/img/hero-banner4.png";
import qrcode from "../../../assets/img/qr-code.png";
// import googleplay from "../../../assets/img/newgoogleplay.png";
// import appstore from "../../../assets/img/whiteappstore.png";

export default () => {
  return (
    <>
      <div className="banner-two-container">
        <img src={HeroBanner} alt="" className="banner-two-bg-img"/>
        <div className="page-container">
          <div className="banner-two-card">
            <div className="left-benefits-card">
              <h4>Get in the driver’s seat and take control of your money</h4>
              <h5>SCAN TO DOWNLOAD</h5>
              <img src={qrcode} className="barcode-img"/>
              <div className="home-download-icons">
                {/* <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a> */}
              </div>
            </div>
            <div className="right-banner-two-card">
              <img src={m21} alt="monthly"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
