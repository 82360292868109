import { getNewToken } from '../data/api';
import useAuth from './useAuth';
import { useNavigate } from 'react-router-dom';

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();
const navigate = useNavigate();
  const refresh = async () => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    try{
      const response = await getNewToken(authData.refreshToken);
      const newAccessToken = response?.data?.data.access_token
      localStorage.setItem('auth', JSON.stringify({ accessToken:newAccessToken }));
      setAuth(prev => {
        return { ...prev, accessToken: response?.data?.data?.access_token };
      });
      return response?.data?.data?.access_token;
    }catch(err){
      if(err?.response?.data?.status === 400){
        localStorage.clear();
        navigate("/signin")
      }
    }

  };
  return refresh;
};

export default useRefreshToken;
