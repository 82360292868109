import React from 'react';
import { Image } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import M1 from "../../../assets/img/image1.png";
import M2 from "../../../assets/img/image2.png";
import M3 from "../../../assets/img/image3.png";
import M4 from "../../../assets/img/image4.png";
import Iphone from "../../../assets/img/iphone.png";

export default () => {
  return (
    <div className ="works-wrapper">
      <div className="page-container">
        <div className="works-heading">
          <h2>How Monthly works</h2>
          <p>We pay the landlord and agent all fees upfront and the tenant pays us back Monthly.</p>
        </div>
        <div className="works-container">
          <div className="works-vertical-section">
            <VerticalTimeline
              layout="1-column-left"
              lineColor="#C5C5C5"
              className="styled-vertical-timeline"
              animate={false}
            >
              <VerticalTimelineElement
                iconStyle={{ background: '#fff', color: '#fff' }}
                icon={<Image src={M1} className="how-to-icon"/>}
              >
                <h5 className="timeline-element-title">Customers download the Monthly App and Register</h5>
                <p>
              We take privacy seriously and don’t collect any address book data from the customer.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                iconStyle={{ background: '#fff', color: '#fff' }}
                icon={<Image src={M2} className="how-to-icon"/>}
              >
                <h5 className="timeline-element-title">They tell us how
                much they need and how long they want to pay back</h5>
                <p>
              Repayment for a ₦1m loan is only ₦100k monthly for 12 months.
              Customers can borrow up to ₦5m to cover all rent expenses including agency fees and service charges.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                iconStyle={{ background: '#fff', color: '#fff' }}
                icon={<Image src={M3} className="how-to-icon"/>}
              >
                <h5 className="timeline-element-title">Get pre-approved in 24 hours</h5>
                <p>
              We'll let the customer know if they’ve been approved the following working day.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                iconStyle={{ background: '#fff', color: '#fff' }}
                icon={<Image src={M4} className="how-to-icon"/>}
              >
                <h5 className="timeline-element-title">Funds get paid to the right people </h5>
                <p>
              We ask the customer to provide bank details
              for the agent, lawyer or landlord so we can
              send the money to the right places.
                </p>
              </VerticalTimelineElement>

            </VerticalTimeline>
          </div>
          <div className="how-to-image-box works-img">
            <Image src={Iphone} alt="how-to-image"/>
          </div>
        </div>
      </div>
    </div>
  );
};
