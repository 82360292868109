import React, { useEffect, useState, useContext } from "react";
import HeroBanner from "../../assets/img/transparent-hero.png";
import mobile from "../../assets/img/mobile-images.png";
import qrcode from "../../assets/img/qr-code.png";
// import googleplay from "../../assets/img/newgoogleplay.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import appstore from "../../assets/img/newappstore.png";
import { useNavigate } from "react-router-dom";
import {
  faCircleCheck,
  faWarning,
  faHandshake,
  faReceipt
} from "@fortawesome/free-solid-svg-icons";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import ApplyNavbar from "../components/ApplyNavbar";
import AppContext from "../../context/AppProvider";

export default function Apply () {
  const navigate = useNavigate();
  const axiosProtected = useAxiosProtected();
  const [latestLoan, setLatestLoan] = useState({});
  const [kycStatus, setKycStatus] = useState(false);
  const { staticConfig } = useContext(AppContext);

  const LATEST_LOAN = "/loans/latest";
  const KYC_COMPLETED = "customers/kyc/completed";

  const handleApplyLink = () => {
    navigate("/apply-loans");
  };

  const handleCompleteKYC = () => {
    navigate("/kyc");
  };

  const handleAdditionalDocs = () => {
    navigate("/apply/upload", {
      state: latestLoan
    });
  };
  useEffect(() => {
    let isMounted = true;
    const handleLatestLoan = async () => {
      try {
        const response = await axiosProtected.get(LATEST_LOAN);
        setLatestLoan(response?.data?.data);
      } catch (_error) {}
    };
    handleLatestLoan();
    const handleKYCheck = () => {
      axiosProtected
        .get(`${KYC_COMPLETED}`)
        .then((res) => {
          if (res?.data?.data?.completed) {
            setKycStatus(true);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            navigate("/signin");
          }
        });
    };
    handleKYCheck();
    return () => {
      isMounted = false;
    };
  }, []);

  const kycText = staticConfig?.consumer_app?.find(
    (config) => config?.key === "kyc_text"
  );

  return (
    <div className="success-wrapper">
      <img src={HeroBanner} alt="" className="success-bg-img" />
      <div className="page-container">
        <ApplyNavbar />
        <div className="success-body">
          {latestLoan?.amount ? (
            <div className="pending-container">
              <div className="card apply-card border-0">
                {latestLoan?.status === "awaiting-decision" && !kycStatus ? (
                  <div
                    className={`text-center approval-status-btn status-btn__rejected mb-4`}
                  >
                    Application not submitted, complete kyc to submit
                  </div>
                ) : (
                  <div
                    className={`text-center approval-status-btn status-btn__${latestLoan?.status} mb-4`}
                  >
                    {latestLoan?.status}
                  </div>
                )}

                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <p>Loan ID</p>
                  <h6>{latestLoan?.code}</h6>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <p>Loan Amount</p>
                  <h5>{latestLoan?.amount}</h5>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <p>Repayment</p>
                  <h5>{latestLoan?.monthly_repayment}</h5>
                </div>
                <div className="item-pair d-flex align-items-center justify-content-between py-1">
                  <p>Duration</p>
                  <h6>{latestLoan?.tenure_in_months}</h6>
                </div>
              </div>
              {latestLoan?.status === "rejected" ? (
                <div className="apply-reason-container">
                  <h6>
                    Unfortunately, it looks like your application was not
                    approved 😟
                  </h6>
                  <p>Reason for Rejection</p>
                  <h3>{latestLoan?.rejection_reason}</h3>
                  <a
                    href="https://blog.monthly.ng/blog/common-reasons-why-your-loan-application-may-have-been-rejected"
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    Read: Common Reasons why your loan application may be
                    rejected
                  </a>
                </div>
              ) : latestLoan?.status === "pre-approved" ? (
                <>
                  <button
                    className="verify-btn mt-3 mb-3 w-100"
                    onClick={handleCompleteKYC}
                  >
                    <div className="verify-icon">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <div className="verify-text">
                      Congratulations. Download Monthly NG app to submit
                      requirements and get funds
                    </div>
                    <div style={{ fontSize: "20px" }} />
                  </button>
                </>
              ) : latestLoan?.status !== "pre-approved" ? (
                <>
                  <button
                    className="verify-btn mt-3 mb-3 w-100"
                    onClick={handleCompleteKYC}
                  >
                    <div className="verify-icon">
                      {kycStatus ? (
                        <FontAwesomeIcon icon={faHandshake} />
                      ) : (
                        <FontAwesomeIcon icon={faReceipt} />
                      )}
                    </div>
                    <div className="verify-text">
                      {kycStatus ? (
                        <>
                          <h2>{kycText?.text}</h2>
                        </>
                      ) : (
                        <h2>
                          Application not submitted. Complete KYC to submit
                        </h2>
                      )}
                    </div>
                    <div style={{ fontSize: "20px" }}>
                      {kycStatus ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          style={{ color: "#17004a" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faWarning}
                          style={{ color: "#6c757d" }}
                        />
                      )}
                    </div>
                  </button>
                  {kycStatus ? (
                    <button
                      className="verify-btn mt-3 mb-3 w-100"
                      onClick={handleAdditionalDocs}
                    >
                      <div className="verify-icon">
                        <FontAwesomeIcon icon={faReceipt} />
                      </div>
                      <div className="verify-text">
                        <p>
                          Upload any additional documents that would help us
                          confirm your income E.g. Contracts for each income or
                          CAC documents
                        </p>
                      </div>
                      <FontAwesomeIcon
                        icon={faWarning}
                        style={{ color: "#FFC83E" }}
                      />
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : (
            <>
              {" "}
              <img
                src={mobile}
                alt="monthly-mobile"
                className="monthly-mobile-image"
              />
              <button className="apply-btn mb-5" onClick={handleApplyLink}>
                APPLY NOW
              </button>
            </>
          )}
          <div className="scan-title mt-2">
            Scan the QR code below to download <span>Monthly</span>{" "}
            {!latestLoan ?? "and apply"}
          </div>
          <img src={qrcode} alt="qr-code" className="success-qr" />
          <h5 className="small-success-title">
            Download <span>Monthly NG</span> App
          </h5>
          <div className="search-title">
            You can also search <span>Monthly NG</span> on
          </div>
          <div className="download-icons">
            {/* <a
              href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
              target="_blank"
            >
              <div>
                <img src={googleplay} alt="googleplay" />
              </div>
            </a>

            <a
              href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
              target="_blank"
            >
              <div>
                <img src={appstore} alt="appstore" />
              </div>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
