export const AppRoutes = {
  Home: { path: "/" },
  MarketingHome: { path: "/register" },
  AgentHome: { path: "/agents" },
  About: { path: "/about" },
  Faqs: { path: "/faqs" },
  HowItWorks: { path: "/how-it-works" },
  Privacy: { path: "/privacy-policy" },
  Terms: { path: "/terms" },
  Register: { path: "/register/:id" },
  AgentRegister: { path: "/agents/:id" },
  Signin: { path: "/signin" },
  Apply: { path: "/apply" },
  ApplyForm: { path: "/apply-loans" },
  // ApplyPending: { path: "/apply-pending" },
  CompleteKyc: { path: "/kyc" },
  Guarantor: { path: "/guarantor" },
  EmployerGuarantor: { path: "/guarantor/employer" },
  EmployeeGuarantor: { path: "/guarantor/employee" },
  VerifyID: { path: "/apply/verify/id"},
  VerifyBank: { path: "/apply/verify/bank"},
  VerifyDoc: { path: "/apply/verify/doc"},
  ForgotPassword: { path: "/forgot-password" },
  Signup: { path: "/register/signup" },
  AgentSignup: { path: "/agents/signup" },
  SuccessAgent: { path: "/agents/signup/success" },
  NotFound: { path: "/404" },
  Upload: { path: "/upload/:token/:type" },
  AdditionalDocs: { path: "/apply/upload" }
};
