import React from 'react';
import { Accordion } from 'react-bootstrap';
import ListItem from './ListItem';

export default () => {
  return (
    <>
      <section className="faq-content">
        <div className="container d-flex justify-content-center">
          <Accordion defaultActiveKey="0">
            <ListItem
              title="How do I download the Monthly app"
              body="Search “Monthly NG” on the AppStore or Playstore.  Or if you're on a mobile phone, visit this link https://onelink.to/monthlywebtoapp."
               eventKey="0"
            />
              <ListItem
              title="How can I access Monthly financing?"
              body="It’s simple. Complete your ID verification then use our loan calculator on the app's homepage to get an instant quote.
                    Just click apply and you’re on your way to stress-free renting."
              eventKey="1"
            />
            <ListItem
              title="How much can I borrow?"
              body={`Monthly offers rent loans up to ${'\u20a6'}5,000,000.`}
              eventKey="2"
            />
              <ListItem
              title="How do I get my Loan?"
              body="Monthly transfers the loan directly to your landlord or their lawyer. To make Monthly affordable for everyone we have to put measures in place that prevent diversion for other purposes."
              eventKey="3"
            />
            <ListItem
              title="What states do you operate in? "
              body="Monthly is only available in Lagos State at the moment but we are working hard to expand to other states."
              eventKey="4"
            />
            <ListItem
              title="What documents do I need to apply?"
              body="A government issued identity card, Bank statement and Proof of address."
              eventKey="5"
            />
            <ListItem
              title="Can I take out a loan? How do I know if I am eligible?"
              body="Anyone 18 or over or on a contract or on a monthly salary looking to rent in Lagos, Nigeria"
              eventKey="6"
            />
            <ListItem
              title="How do I repay my loan?"
              body="Repaying your loan is stress-free. Once your loan is active, you can see exactly how much you’re due to pay and when on your loan page. You can make payments through internet banking or by bank transfer."
              eventKey="7"
            />
            
            <ListItem
              title="Can I repay earlier than stated in my application?"
              body="Yes, absolutely. As we only charge interest on your loan balance, paying off your loan early saves you money. You can always access your current balance on the app. Just let us know and we'll give you a total pay-off quote which is your loan balance + interest due - deposit."
              eventKey="8"
            />
            <ListItem
              title="What happens if I miss one or a few loan repayments?"
              body="We understand that life happens. We do not charge a late fee and you will only be charged interest on the amount due after a 7 day grace period. Contact us in advance of your due date so we know. Outstanding amounts not paid within 30 days or agreed to be rescheduled will lead to an adverse report on your credit file."
              eventKey="9"
            />
            <ListItem
              title="Do i have to rent from Monthly?"
              body="Monthly does not lease any properties. You can finance any rental property of your choice, current or new. We connect only those who ask for help to a list of vetted agent."
              eventKey="10"
            />
   
          </Accordion>
        </div>
      </section>
    </>
  );
};
