import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useValidator from "../../hooks/useValidator";
import useAxiosProtected from "../../hooks/useAxiosProtected";
import ApplyNavbar from "../components/ApplyNavbar";
import { Form } from "@themesberg/react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";
// import Okra from "okra-js";

export default function Upload() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newDocument, setNewDocument] = useState({});
  const [validator, showValidationMessage] = useValidator();
  const {state} = useLocation();

  const UPLOAD_URL_ENDPOINT = "/documents";
//   const OKRA_CONNECTED = "/statements/okra/connected/";

  const axiosProtected = useAxiosProtected();
  const [fileName, setFileName] = useState("Upload pdf");

  //   const [isUploaded, setUploadStatus] = useState(false);

  const handleFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setNewDocument({ ...newDocument, [name]: value });
  };

  useEffect(()=> {
    if(!state){
        navigate("/apply")
    }
  },[])

//   const widgetOkra = () => {
//     Okra.buildWithOptions({
//       name: "Monthly",
//       env: process.env.ENVIRONMENT === "staging" ? "sandbox" : "production",
//       app_id: "", // app_id from your app builder
//       key: process.env.OKRA_KEY,
//       token: process.env.OKRA_TOKEN,
//       products: ["auth", "identity", "balance", "transactions", "income"],
//       onSuccess: function (data) {
//         axiosProtected
//           .post(`${OKRA_CONNECTED}`, {
//             record: data?.record,
//             customer_id: data?.customer_id,
//             status: data?.accounts[0]?.status,
//             account: data?.accounts[0]?.accountId,
//             metadata: data,
//             type: "additional",
//           })
//           .catch((err) => console.log(err));
//         setTimeout(() => navigate("/apply"), 500);
//       },
//       onClose: function () {
//         navigate("/apply");
//       },
//     });
//   };

//   const handleWidget = (e) => {
//     e.preventDefault();
//     widgetOkra();
//   };

  const handleFileChange = (event) => {
    const target = event.target;
    setSelectedFile(target.files[0]);
    setFileName(target.files[0].name.replaceAll(" ", "-"));
  };

  const uploadDocument = async (e) => {
    e.preventDefault();
    const controller = new AbortController();
    if (validator.allValid()) {
      try {
        setIsSubmitting(true);
        const fileName = selectedFile.name.replaceAll(" ", "-");
        const response = await axiosProtected.post(
          `${UPLOAD_URL_ENDPOINT}`,
          {
            type: "additional",
            description: newDocument?.description,
            file_name: fileName,
            resource_id: state?.id,
            check_sum: "",
          },
          { signal: controller.signal }
        );

        if (response.status === 200 && response.data.data) {
          const data = response.data.data;
          await axios.put(data.link, selectedFile, {
            headers: { "Content-Type": `${selectedFile.type}` },
          });
          toast.success("Document Uploaded successfully");
          setTimeout(() => navigate("/apply"), 500);
        } else {
          toast.error(`Unable to get upload link`);
        }
      } catch (e) {
        toast.error(`Unable to upload, please try again`);
      }
      setIsSubmitting(false);
      controller.abort();
    } else {
      showValidationMessage(true);
    }
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    setIsSubmitting(false);
    hiddenFileInput.current.click();
  };

  return (
    <>
      <div className="page-container">
        <ApplyNavbar />
        <div className="pending-container">
          <h2 className="text-center pb-3 mt-5">Upload Documents</h2>
          <p className="text-center">
            You can upload as many documents as possible here
          </p>
          <div className="card apply-card border-0">
            <form className="upload-section">
              <div className="signup-formgroup">
                <label>DESCRIBE DOCUMENT (OPTIONAL)</label>
                <input
                  type="text"
                  name="description"
                  value={newDocument.description}
                  onChange={handleFormChange}
                />
              </div>
              {/* <div className="signup-formgroup">
                <label>DOCUMENT TYPE</label>
                <select
                  name="type"
                  value={newDocument.type}
                  onChange={handleFormChange}
                >
                  <option value="">---Select the one that apply ---</option>
                  {LoanDocumentTypes?.map((doc, i) => {
                    return (
                      <option key={i} value={doc?.key}>
                        {doc?.text}
                      </option>
                    );
                  })}
                </select>
              </div>
              {validator.message("type", newDocument.type, "required")} */}
              <label className="upload-label" htmlFor="description">
                UPLOAD DOCUMENT
              </label>
              <span className="upload-container" onClick={handleClick}>
                {fileName}
              </span>
              <div className="upload--input">
                <Form.Control
                  type="file"
                  accept="application/pdf"
                  name="file"
                  ref={hiddenFileInput}
                  required
                  onChange={handleFileChange}
                />
              </div>
              {validator.message("file", selectedFile, "required")}

              {/* <>
                <br />
                <h2 className="text-center">OR</h2>
                <button
                  className="d-flex align-items-center justify-content-between bg-light border-0 p-3 mt-2 mb-1 w-100 rounded-0"
                  onClick={handleWidget}
                >
                  <span>Get Automatically via internet banking</span>
                </button>
              </> */}

              <button
                onClick={uploadDocument}
                disabled={isSubmitting}
                className="signup-button complete-kyc-btn mt-5"
              >
                PROCEED
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
}
