import React, { useState, useEffect } from "react";
import HeroBanner from "../../../assets/img/hero-banner.png";
import NewCalc from "./NewCalc";
import { useNavigate, useParams, Link } from "react-router-dom";
import useValidator from "../../../hooks/useValidator";
import qrcode from "../../../assets/img/qr-code.png";
import googleplay from "../../../assets/img/googleplay-green.png";
import appstore from "../../../assets/img/appstore-purple.png";
import websignup from "../../../assets/img/web-signup.png";
import axios from "axios";

export default function Banner() {
  const navigate = useNavigate();
  const { id } = useParams();
  localStorage.setItem("refer", id ? id : "");
  const getEmployment = () => localStorage.getItem("employment_type");
  const getLoan = () => localStorage.getItem("loan_amount");
  const getRepayment = () => localStorage.getItem("repayment_amount");
  const getDuration = () => localStorage.getItem("duration");

  const [getStartedDetails, setgetStartedDetail] = useState({
    email: "",
    url: "",
    referral_code: "",
  });

  const [validator, showValidationMessage] = useValidator();

  const handleFormChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setgetStartedDetail({ ...getStartedDetails, [name]: value });
    localStorage.setItem("email", target.value);
  };

  const removeStoredItems = () => {
    localStorage.removeItem("loan_amount");
    localStorage.removeItem("repayment_amount");
    localStorage.removeItem("duration");
    localStorage.removeItem("employment_type");
  };

  useEffect(() => {
    setgetStartedDetail({
      ...getStartedDetails,
      referral_code: id,
      url: window.location.href,
    });
  }, []);

  const handleReg = async () => {
    const body = {
      ...getStartedDetails,
      employment_type: getEmployment(),
      loan_amount: getLoan(),
      repayment_amount: getRepayment(),
      duration: getDuration(),
    };
    if (validator.allValid()) {
      await axios
        .post(`${process.env.API_URL}/leads`, body)
        .then(function (_response) {
          removeStoredItems();
        })
        .catch(function (_error) {
          removeStoredItems();
        });
      navigate("/register/signup");
    } else {
      showValidationMessage(true);
    }
  };

  const scrollToSignup = () => {
    window.scrollTo({ top: 40, left: 0, behavior: "smooth" });
  };

  const { email } = getStartedDetails;

  return (
    <div className="marketing-container">
      <div className="banner-wrapper">
        <img src={HeroBanner} alt="" className="banner-background" />
        <div className="banner-container">
          {/* <div className="marketing-navbar">
            <Link to="/">
              <img src={logo} alt="monthly logo"/>
            </Link>
          </div> */}
          <div className="banner-section">
            <div className="left-banner">
              <h2>
                Find your dream <span>home</span> and pay rent{" "}
                <span>monthly</span>
              </h2>
              <p id="calculator">
                Get a house rent loan of up to ₦5,000,000 approved in 24 hours.
              </p>
              <div className="barcode-section">
                <h6>Scan to download app</h6>
                <img src={qrcode} className="barcode-img" />
              </div>

              <div className="banner-download-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=ng.monthly.mobile"
                  target="_blank"
                >
                  <div>
                    <img src={googleplay} alt="googleplay" />
                  </div>
                </a>

                <a
                  href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024"
                  target="_blank"
                >
                  <div>
                    <img src={appstore} alt="appstore" />
                  </div>
                </a>
                <Link to="/register/signup">
                  <div>
                    <img src={websignup} alt="sign up" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="right-banner">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleFormChange}
                placeholder="Please input your email address"
              />
              <button onClick={handleReg}>GET STARTED</button>
              {validator.message(
                "email",
                getStartedDetails.email,
                "required|email"
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="calculator-wrapper">
        <div className="new-calculator-section">
          <div className="left-calculator">
            <h4>You deserve to live the life you want</h4>
            <p>Live on your own terms and take it one day at a time</p>
            <button onClick={scrollToSignup} className="large-get-started-btn">
              GET STARTED
            </button>
          </div>
          <div className="right-calculator">
            <div className="new-calculator">
              <NewCalc />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
