import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./context/AuthProvider";
import { AppProvider } from './context/AppProvider';

import "./scss/app.scss";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AppProvider>
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<App/>}/>
      </Routes>
      </AuthProvider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);
