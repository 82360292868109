import { axiosProtected } from "../data/api";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
// import useAuth from "./useAuth";

const useAxiosProtected = (token = '') => {
  const refresh = useRefreshToken();
  // const { auth } = useAuth();
  const auth = JSON.parse(localStorage.getItem('auth'));
    let accessToken = token ? token : auth?.accessToken
    useEffect(() => {
    const requestIntercept = axiosProtected.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = accessToken;
        }
        return config;
      }, (error) => Promise.reject(error)
    );
    const responseIntercept = axiosProtected.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = newAccessToken;
          return axiosProtected(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosProtected.interceptors.response.eject(requestIntercept);
      axiosProtected.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosProtected;
};

export default useAxiosProtected;
