export const Genders = [
  {
    key: "male",
    text: "Male"
  },
  {
    key: "female",
    text: "Female"
  }
];

export const Months = [
  { key: "12 Months", value: 12 },
  { key: "11 Months", value: 11 },
  { key: "10 Months", value: 10 },
  { key: "9 Months", value: 9 },
  { key: "8 Months", value: 8 },
  { key: "7 Months", value: 7 },
  { key: "6 Months", value: 6 },
  { key: "5 Months", value: 5 },
  { key: "4 Months", value: 4 },
  { key: "3 Months", value: 3 },
  { key: "2 Months", value: 2 },
  { key: "1 Months", value: 1 },
];

export const MaritalStatus = [
  {
    key: "single",
    text: "Single"
  },
  {
    key: "married",
    text: "Married"
  },
  {
    key: "living-together",
    text: "Living Together"
  },
  {
    key: "divorced-separated",
    text: "Divorced/Separated"
  },
  {
    key: "widowed",
    text: "Widowed"
  }
];

export const IncomeSourceTypes = [
  {
    key: "Full Time Employment",
    value: "full-time-employment"
  },
  {
    key: "Part Time Employment",
    value: "part-time-employment"
  },
  {
    key: "Self Employed",
    value: "self-employed"
  },
  {
    key: "Contract",
    value: "contract-employment"
  },

]

export const LoanDocumentTypes = [
  {
    key: "bank-statement",
    text: "Bank Statement"
  },
  {
    key: "credit-report",
    text: "Credit Report"
  },
  {
    key: "direct-debit-mandate",
    text: "Direct Debit mandate"
  },
  {
    key: "hr-acknowledgement",
    text: "HR Acknowledgement"
  },
  {
    key: "landlord-details",
    text: "Landlord Details"
  },
  {
    key: "loan-agreement",
    text: "Loan Agreement"
  },
  {
    key: "loan-application",
    text: "Loan Application"
  },
  {
    key: "property-details",
    text: "Property Details"
  },
  {
    key: "rent-agreement",
    text: "Rent Agreement"
  },
  {
    key: "additional",
    text: "Additional"
  },
];


export const KycDocumentTypes = [
  {
    key: "bank-statement",
    text: "Bank Statement"
  },
  {
    key: "smile-verification",
    text: "Smile Verification"
  },
  {
    key: "identity",
    text: "Identity"
  },
  {
    key: "proof-of-address",
    text: "Proof of Address"
  },
  {
    key: "selfie",
    text: "Selfie"
  }
];

export const NigerianStates = [
  {key:"Abia", value: "abia" },
  {key: "Adamawa", value: "adamawa"},
  {key: "Akwa Ibom", value:"akwa-ibom"},
  {key: "Anambra", value:"anambra"},
  {key: "Bauchi", value:"bauchi"},
  {key: "Benue", value:"benue"},
  {key: "Borno", value:"borno"},
  {key: "Bayelsa", value:"bayelsa"},
  {key: "Cross River", value:"cross-river"},
  {key: "Delta", value:"delta"},
  {key: "Ebonyi", value:"ebonyi"},
  {key: "Edo", value:"edo"},
  {key: "Ekiti", value:"ekiti"},
  {key: "Enugu", value:"enugu"},
  {key: "Federal Capital Territory", value:"federal-capital-territory"},
  {key: "Gombe", value:"gombe"},
  {key: "Jigawa", value:"jigawa"},
  {key: "Imo", value:"imo"},
  {key: "Kaduna", value:"kaduna"},
  {key: "Kano", value:"kano"},
  {key: "Katsina", value:"katsina"},
  {key: "Kebbi", value:"kebbi"},
  {key: "Kogi", value:"kogi"},
  {key: "Kwara", value:"kwara"},
  {key: "Lagos", value:"lagos"},
  {key: "Nasarawa", value:"nasarawa"},
  {key: "Niger", value:"niger"},
  {key: "Ogun", value:"ogun"},
  {key: "Ondo", value:"ondo"},
  {key: "Rivers", value:"rivers"},
  {key: "Oyo", value:"oyo"},
  {key: "Osun", value:"osun"},
  {key: "Sokoto", value:"sokoto"},
  {key: "Plateau", value:"plateau"},
  {key: "Taraba", value:"taraba"},
  {key: "Yobe", value:"yobe"},
  {key: "Zamfara", value:"zamfara"},
];

export const LoanRecoveryStages = [
  {
    key: "current",
    text: "Current"
  },
  {
    key: "overdue",
    text: "Overdue"
  },
  {
    key: "under-performing",
    text: "Under performing"
  },
  {
    key: "non-performing",
    text: "Non performing"
  },
  {
    key: "sold-to-recovery",
    text: "Sold to recovery"
  },
  {
    key: "written-off",
    text: "Written off"
  }
];

export const TransactionTypes = [
  {
    key: "loan-fee-and-deposit",
    text: "Loan fee and deposit"
  },
  {
    key: "repayment",
    text: "Repayment"
  }
];

export const TransactionMethods = [
  {
    key: "bank-transfer",
    text: "Bank transfer"
  },
  {
    key: "cash",
    text: "Cash"
  },
  {
    key: "direct-debit",
    text: "Direct debit"
  }
];

export const LoadDecisions = [
  {
    key: "confirm",
    text: "Approve"
  },
  {
    key: "deny",
    text: "Deny"
  }
];

export const EmploymentType = [
  {
    key: "probation",
    text: "Probation"
  },
  {
    key: "contract",
    text: "Contract"
  },
  {
    key: "confirmed",
    text: "Confirmed"
  }
];

export const RejectionReasons = [
  {
    key: "no-credit-profile",
    text: "No credit profile"
  },
  {
    key: "high-current-credit",
    text: "High current credit"
  },
  {
    key: "past-credit-behaviour",
    text: "Past credit behaviour"
  },
  {
    key: "irregularity-of-income",
    text: "Irregularity of income"
  },
  {
    key: "insufficient-income",
    text: "Insufficient income"
  },
  {
    key: "high-current-expenditure",
    text: "High current expenditure"
  },
  {
    key: "loan-requirements",
    text: "Loan requirements"
  },
  {
    key: "location",
    text: "Location"
  },
  {
    key: "verifications",
    text: "Verifications"
  },
  {
    key: "withdrawal-by-customer",
    text: "Withdrawal by customer"
  },
  {
    key: "non-response-by-customer",
    text: "Non-response by customer"
  }
];

export const Banks = [
  {
      name: "9mobile 9Payment Service Bank",
      slug: "9mobile-9payment-service-bank-ng",
      code: "120001"
  },
  {
      name: "Abbey Mortgage Bank",
      slug: "abbey-mortgage-bank",
      code: "801"
  },
  {
      name: "Above Only MFB",
      slug: "above-only-mfb",
      code: "51204"
  },
  {
      name: "Abulesoro MFB",
      slug: "abulesoro-mfb-ng",
      code: "51312"
  },
  {
      name: "Access Bank",
      slug: "access-bank",
      code: "044"
  },
  {
      name: "Access Bank (Diamond)",
      slug: "access-bank-diamond",
      code: "063"
  },
  {
      name: "Accion Microfinance Bank",
      slug: "accion-microfinance-bank-ng",
      code: "602"
  },
  {
      name: "Ahmadu Bello University Microfinance Bank",
      slug: "ahmadu-bello-university-microfinance-bank-ng",
      code: "50036"
  },
  {
      name: "Airtel Smartcash PSB",
      slug: "airtel-smartcash-psb-ng",
      code: "120004"
  },
  {
      name: "AKU Microfinance Bank",
      slug: "aku-mfb",
      code: "51336"
  },
  {
      name: "ALAT by WEMA",
      slug: "alat-by-wema",
      code: "035A"
  },
  {
      name: "Amju Unique MFB",
      slug: "amju-unique-mfb",
      code: "50926"
  },
  {
      name: "AMPERSAND MICROFINANCE BANK",
      slug: "ampersand-microfinance-bank-ng",
      code: "51341"
  },
  {
      name: "Aramoko MFB",
      slug: "aramoko-mfb",
      code: "50083"
  },
  {
      name: "ASO Savings and Loans",
      slug: "asosavings",
      code: "401"
  },
  {
      name: "Astrapolaris MFB LTD",
      slug: "astrapolaris-mfb",
      code: "MFB50094"
  },
  {
      name: "Bainescredit MFB",
      slug: "bainescredit-mfb",
      code: "51229"
  },
  {
      name: "Banc Corp Microfinance Bank",
      slug: "banc-corp-microfinance-bank-ng",
      code: "50117"
  },
  {
      name: "Bowen Microfinance Bank",
      slug: "bowen-microfinance-bank",
      code: "50931"
  },
  {
      name: "Branch International Financial Services Limited",
      slug: "branch",
      code: "FC40163"
  },
  {
      name: "Carbon",
      slug: "carbon",
      code: "565"
  },
  {
      name: "CASHCONNECT MFB",
      slug: "cashconnect-mfb-ng",
      code: "865"
  },
  {
      name: "CEMCS Microfinance Bank",
      slug: "cemcs-microfinance-bank",
      code: "50823"
  },
  {
      name: "Chanelle Microfinance Bank Limited",
      slug: "chanelle-microfinance-bank-limited-ng",
      code: "50171"
  },
  {
      name: "Citibank Nigeria",
      slug: "citibank-nigeria",
      code: "023"
  },
  {
      name: "Consumer Microfinance Bank",
      slug: "consumer-microfinance-bank-ng",
      code: "50910"
  },
  {
      name: "Corestep MFB",
      slug: "corestep-mfb",
      code: "50204"
  },
  {
      name: "Coronation Merchant Bank",
      slug: "coronation-merchant-bank-ng",
      code: "559"
  },
  {
      name: "County Finance Limited",
      slug: "county-finance-limited",
      code: "FC40128"
  },
  {
      name: "Crescent MFB",
      slug: "crescent-mfb",
      code: "51297"
  },
  {
      name: "Dot Microfinance Bank",
      slug: "dot-microfinance-bank-ng",
      code: "50162"
  },
  {
      name: "Ecobank Nigeria",
      slug: "ecobank-nigeria",
      code: "050"
  },
  {
      name: "Ekimogun MFB",
      slug: "ekimogun-mfb-ng",
      code: "50263"
  },
  {
      name: "Ekondo Microfinance Bank",
      slug: "ekondo-microfinance-bank-ng",
      code: "098"
  },
  {
      name: "Eyowo",
      slug: "eyowo",
      code: "50126"
  },
  {
      name: "Fairmoney Microfinance Bank",
      slug: "fairmoney-microfinance-bank-ng",
      code: "51318"
  },
  {
      name: "Fidelity Bank",
      slug: "fidelity-bank",
      code: "070"
  },
  {
      name: "Firmus MFB",
      slug: "firmus-mfb",
      code: "51314"
  },
  {
      name: "First Bank of Nigeria",
      slug: "first-bank-of-nigeria",
      code: "011"
  },
  {
      name: "First City Monument Bank",
      slug: "first-city-monument-bank",
      code: "214"
  },
  {
      name: "FirstTrust Mortgage Bank Nigeria",
      slug: "firsttrust-mortgage-bank-nigeria-ng",
      code: "107"
  },
  {
      name: "FLOURISH MFB",
      slug: "flourish-mfb-ng",
      code: "50315"
  },
  {
      name: "FSDH Merchant Bank Limited",
      slug: "fsdh-merchant-bank-limited",
      code: "501"
  },
  {
      name: "Gateway Mortgage Bank LTD",
      slug: "gateway-mortgage-bank",
      code: "812"
  },
  {
      name: "Globus Bank",
      slug: "globus-bank",
      code: "00103"
  },
  {
      name: "GoMoney",
      slug: "gomoney",
      code: "100022"
  },
  {
      name: "Goodnews Microfinance Bank",
      slug: "goodnews-microfinance-bank-ng",
      code: "50739"
  },
  {
      name: "Greenwich Merchant Bank",
      slug: "greenwich-merchant-bank-ng",
      code: "562"
  },
  {
      name: "Guaranty Trust Bank",
      slug: "guaranty-trust-bank",
      code: "058"
  },
  {
      name: "Hackman Microfinance Bank",
      slug: "hackman-microfinance-bank",
      code: "51251"
  },
  {
      name: "Hasal Microfinance Bank",
      slug: "hasal-microfinance-bank",
      code: "50383"
  },
  {
      name: "Heritage Bank",
      slug: "heritage-bank",
      code: "030"
  },
  {
      name: "HopePSB",
      slug: "hopepsb-ng",
      code: "120002"
  },
  {
      name: "Ibile Microfinance Bank",
      slug: "ibile-mfb",
      code: "51244"
  },
  {
      name: "Ikoyi Osun MFB",
      slug: "ikoyi-osun-mfb",
      code: "50439"
  },
  {
      name: "Ilaro Poly Microfinance Bank",
      slug: "ilaro-poly-microfinance-bank-ng",
      code: "50442"
  },
  {
      name: "Infinity MFB",
      slug: "infinity-mfb",
      code: "50457"
  },
  {
      name: "Jaiz Bank",
      slug: "jaiz-bank",
      code: "301"
  },
  {
      name: "Kadpoly MFB",
      slug: "kadpoly-mfb",
      code: "50502"
  },
  {
      name: "Keystone Bank",
      slug: "keystone-bank",
      code: "082"
  },
  {
      name: "Kredi Money MFB LTD",
      slug: "kredi-money-mfb",
      code: "50200"
  },
  {
      name: "Kuda Bank",
      slug: "kuda-bank",
      code: "50211"
  },
  {
      name: "Lagos Building Investment Company Plc.",
      slug: "lbic-plc",
      code: "90052"
  },
  {
      name: "Links MFB",
      slug: "links-mfb",
      code: "50549"
  },
  {
      name: "Living Trust Mortgage Bank",
      slug: "living-trust-mortgage-bank",
      code: "031"
  },
  {
      name: "Lotus Bank",
      slug: "lotus-bank",
      code: "303"
  },
  {
      name: "Mayfair MFB",
      slug: "mayfair-mfb",
      code: "50563"
  },
  {
      name: "Mint MFB",
      slug: "mint-mfb",
      code: "50304"
  },
  {
      name: "Moniepoint MFB",
      slug: "moniepoint-mfb-ng",
      code: "50515"
  },
  {
      name: "MTN Momo PSB",
      slug: "mtn-momo-psb-ng",
      code: "120003"
  },
  {
      name: "Optimus Bank Limited",
      slug: "optimus-bank-ltd",
      code: "00107"
  },
  {
      name: "Paga",
      slug: "paga",
      code: "100002"
  },
  {
      name: "PalmPay",
      slug: "palmpay",
      code: "999991"
  },
  {
      name: "Parallex Bank",
      slug: "parallex-bank",
      code: "104"
  },
  {
      name: "Parkway - ReadyCash",
      slug: "parkway-ready-cash",
      code: "311"
  },
  {
      name: "Paycom",
      slug: "paycom",
      code: "999992"
  },
  {
      name: "Peace Microfinance Bank",
      slug: "peace-microfinance-bank-ng",
      code: "50743"
  },
  {
      name: "Personal Trust MFB",
      slug: "personal-trust-mfb-ng",
      code: "51146"
  },
  {
      name: "Petra Mircofinance Bank Plc",
      slug: "petra-microfinance-bank-plc",
      code: "50746"
  },
  {
      name: "Polaris Bank",
      slug: "polaris-bank",
      code: "076"
  },
  {
      name: "Polyunwana MFB",
      slug: "polyunwana-mfb-ng",
      code: "50864"
  },
  {
      name: "PremiumTrust Bank",
      slug: "premiumtrust-bank-ng",
      code: "105"
  },
  {
      name: "Providus Bank",
      slug: "providus-bank",
      code: "101"
  },
  {
      name: "QuickFund MFB",
      slug: "quickfund-mfb",
      code: "51293"
  },
  {
      name: "Rand Merchant Bank",
      slug: "rand-merchant-bank",
      code: "502"
  },
  {
      name: "Refuge Mortgage Bank",
      slug: "refuge-mortgage-bank",
      code: "90067"
  },
  {
      name: "Rigo Microfinance Bank Limited",
      slug: "rigo-microfinance-bank-limited-ng",
      code: "51286"
  },
  {
      name: "ROCKSHIELD MICROFINANCE BANK",
      slug: "rockshield-microfinance-bank-ng",
      code: "50767"
  },
  {
      name: "Rubies MFB",
      slug: "rubies-mfb",
      code: "125"
  },
  {
      name: "Safe Haven MFB",
      slug: "safe-haven-mfb-ng",
      code: "51113"
  },
  {
      name: "Safe Haven Microfinance Bank Limited",
      slug: "safe-haven-microfinance-bank-limited-ng",
      code: "951113"
  },
  {
      name: "Shield MFB",
      slug: "shield-mfb-ng",
      code: "50582"
  },
  {
      name: "Solid Allianze MFB",
      slug: "solid-allianze-mfb",
      code: "51062"
  },
  {
      name: "Solid Rock MFB",
      slug: "solid-rock-mfb",
      code: "50800"
  },
  {
      name: "Sparkle Microfinance Bank",
      slug: "sparkle-microfinance-bank",
      code: "51310"
  },
  {
      name: "Stanbic IBTC Bank",
      slug: "stanbic-ibtc-bank",
      code: "221"
  },
  {
      name: "Standard Chartered Bank",
      slug: "standard-chartered-bank",
      code: "068"
  },
  {
      name: "Stellas MFB",
      slug: "stellas-mfb",
      code: "51253"
  },
  {
      name: "Sterling Bank",
      slug: "sterling-bank",
      code: "232"
  },
  {
      name: "Suntrust Bank",
      slug: "suntrust-bank",
      code: "100"
  },
  {
      name: "Supreme MFB",
      slug: "supreme-mfb-ng",
      code: "50968"
  },
  {
      name: "TAJ Bank",
      slug: "taj-bank",
      code: "302"
  },
  {
      name: "Tanadi Microfinance Bank",
      slug: "tanadi-microfinance-bank-ng",
      code: "090560"
  },
  {
      name: "Tangerine Money",
      slug: "tangerine-money",
      code: "51269"
  },
  {
      name: "TCF MFB",
      slug: "tcf-mfb",
      code: "51211"
  },
  {
      name: "Titan Bank",
      slug: "titan-bank",
      code: "102"
  },
  {
      name: "Titan Paystack",
      slug: "titan-paystack",
      code: "100039"
  },
  {
      name: "U&C Microfinance Bank Ltd (U AND C MFB)",
      slug: "uc-microfinance-bank-ltd-u-and-c-mfb-ng",
      code: "50840"
  },
  {
      name: "Uhuru MFB",
      slug: "uhuru-mfb-ng",
      code: "MFB51322"
  },
  {
      name: "Unaab Microfinance Bank Limited",
      slug: "unaab-microfinance-bank-limited-ng",
      code: "50870"
  },
  {
      name: "Unical MFB",
      slug: "unical-mfb",
      code: "50871"
  },
  {
      name: "Unilag Microfinance Bank",
      slug: "unilag-microfinance-bank-ng",
      code: "51316"
  },
  {
      name: "Union Bank of Nigeria",
      slug: "union-bank-of-nigeria",
      code: "032"
  },
  {
      name: "United Bank For Africa",
      slug: "united-bank-for-africa",
      code: "033"
  },
  {
      name: "Unity Bank",
      slug: "unity-bank",
      code: "215"
  },
  {
      name: "VFD Microfinance Bank Limited",
      slug: "vfd",
      code: "566"
  },
  {
      name: "Waya Microfinance Bank",
      slug: "waya-microfinance-bank-ng",
      code: "51355"
  },
  {
      name: "Wema Bank",
      slug: "wema-bank",
      code: "035"
  },
  {
      name: "Zenith Bank",
      slug: "zenith-bank",
      code: "057"
  }
]
