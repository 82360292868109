import React from 'react';
import MonthlyLogo from "../../../assets/img/brand/logo.png";
// import googleplay from "../../../assets/img/newgoogleplay.png";
// import appstore from "../../../assets/img/newappstore.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppRoutes } from "../../../routes";
import { faTwitter, faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

export default function Footer () {
  const currentYear = new Date().getFullYear();
  return (
    <div className="new-footer-wrapper">
      <div className="page-container">
        <div className="new-flex-footer">
          <div className="each-footer-section">
            <div>
              <img src={MonthlyLogo} className="new-footer-logo"/>
            </div>
            <div className="footer-icon-section">
              <a href="https://facebook.com/monthlyfinance" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF}/>
              </a>
              <a href="https://twitter.com/monthlyfinance" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter}/>
              </a>
              <a href="https://www.instagram.com/monthlyfinance/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram}/>
              </a>
            </div>
            <div><a href="mailto:support@monthly.ng">support@monthly.ng</a></div>
            <div>Whatsapp: <p><b>+234 916 173 4720</b></p></div>
            <div>Phone: <p><b> +234 908 899 1097</b></p></div>
          </div>
          <div className="each-footer-section">
            <div>
              <h4> About Product</h4>
            </div>
            <div><Link to={`${AppRoutes.Faqs.path}`}>FAQS</Link></div>
            <div><Link to={`${AppRoutes.HowItWorks.path}`} >How it works</Link></div>
            <div><Link to={`${AppRoutes.Terms.path}`}>Terms and Conditions</Link></div>
            <div><Link to={`${AppRoutes.Privacy.path}`}>Privacy Policy</Link></div>
          </div>
          {/* <div className="each-footer-section"> */}
          {/* <div>
              <h4> Useful Links</h4>
            </div> */}
          {/* <div><a href="https://blog.monthly.ng" target="_blank" rel="noopener noreferrer">Blog</a></div> */}
          {/* <div><Link to="/agents">Agents</Link></div> */}
          {/* <div><Link to="">Download</Link></div> */}
          {/* </div> */}
          <div className="each-footer-section">
            <div>
              <h4> Company</h4>
            </div>
            <div><Link to={`${AppRoutes.About.path}`} >About us</Link></div>
            <div><a href="https://monthly.factorialhr.com/#jobs" target="_blank" rel="noopener noreferrer">Careers</a></div>
            <div><a href="https://intercom.help/monthlyng/en/" target="_blank" rel="noopener noreferrer">Help</a></div>
            {/* <div><a href="mailto:support@monthly.ng">Help</a></div> */}
          </div>
          <div className="each-footer-section">
            {/* <div>
              <a href=" https://play.google.com/store/apps/details?id=ng.monthly.mobile" target="_blank" rel="noopener noreferrer">
                <img src={googleplay} alt="googleplay"/>
              </a>
            </div>
            <div>
              <a href="https://apps.apple.com/ng/app/monthly-ng-quick-rent-loans/id1634279024" target="_blank" rel="noopener noreferrer">
                <img src={appstore} alt="appstore"/>
              </a>
            </div> */}


          </div>
        </div>
        <hr/>
        <div className="sub-footer">
          <div>Monthly is a trademark of Instalment Solutions Limited (RC 1916060)</div>
          <div>   <span className="me-2">&copy;</span>
            <span>{currentYear} All rights reserved</span></div>
        </div>
      </div>
    </div>
  );
}
