import React from 'react';
import { Image } from 'react-bootstrap';

import M18 from "../../../assets/img/m18.png";

export default () => {
  return (
    <>
      <section className="about page-padding">
        <div className="d-flex align-items-center justify-content-center flex-column-reverse flex-md-row ">
          <div className="about-section">
            <div className="about-text-box">
              <h2 className="text-bold my-4 my-md-5">About Monthly</h2>
              <div className="about-text-body">
                The next era of financing. We believe in helping you to manage your responsibilities by taking
                it slow—or one month at a time. We do this by offering financing that is simple, straightforward,
                and affordable to YOU. With a monthly percentage rate, you only pay interest on what you still
                owe, meaning you automatically save on interest when you pay back early. We have no intention
                of trapping you in a vicious cycle of debt.
              </div>
            </div>
          </div>
          <div className="about-section">
            <div className="about-image-box"><Image alt="happy tenants" src={M18} className="about-image"/></div>
          </div>
        </div>
      </section>
    </>
  );
};
