import React from 'react';
import Banner from './components/agentHome/Banner';
import WhyRental from './components/agentHome/WhyRentals';
import CloseDeals from './components/agentHome/CloseDeals';
import Steps from './components/agentHome/Steps';
import Review from './components/agentHome/Review';
import Footer from './components/home/Footer';

export default function NewHome () {
  return (
    <>
      <Banner/>
      <WhyRental/>
      <CloseDeals/>
      <Steps/>
      <Review/>
      <Footer/>
    </>
  );
}
