import React, { useState, useEffect } from 'react';
import logo from "../../../assets/img/brand/logo.png";
import AgentBanner from '../../../assets/img/m6.png';
import { useParams, useNavigate, Link } from 'react-router-dom';

export default function Banner () {
  const { id } = useParams();
  const navigate = useNavigate();
  localStorage.setItem('refer', id ? id : '');
  const [getStartedDetails, setgetStartedDetail] = useState({
    email: "",
    url: "",
    referral_code: ""
  });

  useEffect(() => {
    setgetStartedDetail({ ...getStartedDetails, referral_code: id, url: window.location.href });
  }, []);

  const handleSignup = () => navigate('/agents/signup');

  return (
    <div className="marketing-container">
      <div className="agent-banner-wrapper">
        <div className="agent-banner-container">
          <div className="marketing-navbar">
            <Link to ="/">
              <img src={logo} alt="monthly logo"/>
            </Link>
          </div>
          <div className="agent-banner-section">
            <div className="agent-left-banner">
              <div className="agent-banner-bg"/>
              <img src={AgentBanner} alt="monthly"/>
            </div>
            <div className="agent-right-banner">
              <h2>Close more rentals and earn up to 90% more per deal.</h2>
              <p>Refer tenants for Monthly rental financing to earn up to N150,000 extra in Commission.</p>
              <button className="agent-sign-up-button" onClick={handleSignup}>Sign up</button>
            </div>
          </div>
        </div>
        <div className="page-container">
          <div className="agent-sub-banner">

            <div className="left-agent-sub">
              <div className="agent-banner-overflow"/>
              <h2>Get high-quality referrals from Monthly.</h2>
              <p>Most of Monthly’s pre-approved customers are just starting to look for properties.</p>
            </div>
            <div className="right-agent-sub">
              <h2>Set up alerts for your areas to get leads from
            prospective tenants with secured funding.</h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
