import React from 'react';
import AgentBanner from '../../../assets/img/m5.png';
import { useNavigate } from 'react-router-dom';

export default function CloseDeals () {
  const navigate = useNavigate();
  const handleSignup = () => navigate('/agents/signup');
  return (
    <div className="page-container">
      <div className="agent-banner-section">
        <div className="agent-left-banner-two">
          <h2>Close deals faster and at the price the landlord wants.
            Earn more per deal and get high-quality leads.</h2>
          <button className="agent-sign-up-button" onClick={handleSignup}>Get Started</button>
        </div>
        <div className="agent-right-banner-two">
          <div className="agent-banner-bg-two"/>
          <img src={AgentBanner} alt="monthly"/>
        </div>

      </div>
    </div>
  );
}
